import { ref, reactive, watch } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useBuilder } from "@/views/builder/stores/Builder";
import menuList from "@/views/builder/config/MenuConfig";
import { sections, columnTableTemplate, columnSettingsTemplate, componentFiltersTemplate } from "@/views/builder/config/BuilderConfig";
import { Modal } from "bootstrap";

export function useComponent() {

  const store = useBuilder();

  const componentData = ref({}) as any
  const componentStatus = ref(false)
  const componentOptions = ref() as any
  const transferData = ref({});
  const sectionData = ref() as any
  const sectionLoading = ref(false);
  const sectionStatus = ref(false);

  interface Component {
    name: string;
    type: "table" | "form";
    endpoint: string;
    route: string;
    title: string;
    status: 1 | 2;
    toggle: "open" | "closed";

    filters: string;
    fields: string;
    columns: string;
  }

  interface General {
    name: null,
    type: null,
    endpoint: null,
    route: null,
    title: null,
    status: null,
    toggle: null,
  }

  const componentTypes = [
    { value: 'table', label: 'Table' },
    { value: 'form', label: 'Form' },
  ]

  const initComponent = async (uid) => {
    // if (!store.getStatusComponent) {
    await store.fetchComponent(uid);
    // }
    store.$patch({ currentComponent: { uid: uid } })

    await store.fetchComponentOptions();

    componentStatus.value = true;
  }

  const initSection = async (name) => {
    store.$patch((state) => {
      state.currentSection.name = name;
      state.currentSection.status = false;
    })

    await initComponent(store.getCurrentComponentUid)
    const sectionTemplate = sections.find(section => section.name == name);
    const sectionData = await createSectionData(sectionTemplate, name)
  }

  const createSectionData = async (template, type: string) => {

    if (type == "columns" || type == "filters") {
      sectionData.value = await generateColumnTemplate(type);
      // createTable(template, type);

    } else if (type == "general") {
      const fields = template.items;

      const templateFields = [] as any

      await fields.map((field) => {
        const newElement = {
          name: field.name,
          label: field.label,
          type: field.type,
          defaultValue: store.getCurrentComponent[field.name]
        }

        // Create data set for fields
        templateFields.push(newElement);
      })

      sectionData.value = templateFields;
    }

    store.$patch((state) => {
      state.currentSection.data = sectionData.value;
      state.currentSection.status = true;
    })

    return
  }

  // const createTable = async (template) => {
  //   const tableData = await generateColumnTemplate(store.getCurrentComponent.columns);
  //   return tableData
  // }

  const generateColumnTemplate = async (type) => {
    const data = store.getCurrentComponent[type];
    const template = [] as any
    if (data) {
      for (const field of JSON.parse(data)) {
        const element = type == "columns" ? { ...columnTableTemplate } : type == "filters" ? { ...componentFiltersTemplate } : null
        let type1;
        if (element) {
          for (const [key, value] of Object.entries(field)) {
            if (element[key] !== undefined) {
              element[key] = value;
            }
            type1 = element.type;
          }


          if (Object.prototype.hasOwnProperty.call(element, 'settings')) {
            element['settings'] = await createColumnTemplateSettings(type1, field.settings);
          }

          template.push(element);
        }
      }
    }

    return template;
  }

  const createColumnTemplateSettings = (type, settings) => {
    const template = {} as any
    const setting = columnSettingsTemplate.find(setting => setting.name == type) || false;

    if (settings && setting) {
      for (const field of setting.fields) {
        template[field.name] = ''

        if (Object.keys(settings).length) {
          for (const [key, value] of Object.entries(settings)) {
            // if (field[key] !== undefined) {
            template[key] = value
            // }
          }
        }
      }
    }
    return template;
  }

  const updateComponent = () => {
    // const currentComponentData = store.getCurrentComponent;
    const currentSection = store.getCurrentSectionName

    // for (const key in sectionData.value) if (key in currentComponentData) currentComponentData[key] = sectionData.value[key];

    store.$patch((state) => {
      state.currentComponent.data[currentSection] = JSON.stringify(store.currentSection.data)
      state.transferData[currentSection] = JSON.stringify(store.currentSection.data)
    })

  }

  const saveComponent = async () => {
    await store.saveComponent();
  }

  return { initComponent, menuList, componentData, componentStatus, initSection, sectionData, sectionLoading, sectionStatus, componentOptions, updateComponent, saveComponent }
}