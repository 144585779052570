import { ref } from "vue";
import { useBuilder } from "@/views/builder/stores/Builder";
import { columnSettingsTemplate } from "@/views/builder/config/BuilderConfig";

const modalData = ref();

export function useModal() {
  const store = useBuilder();

  const modalId = ref();
  const modalTitle = ref();
  const modalRenderAmount = ref(0);

  const renderModal = async (rowId, fieldName, fieldType) => {
    modalRenderAmount.value += 1;
    modalId.value = 'es_modal_builder_' + rowId;
    modalTitle.value = fieldName; 
    modalData.value = await createData(fieldType, fieldName);
  }

  const createData = async (fieldType, fieldName) => {
    const fieldTypeTemplate = columnSettingsTemplate.find(setting => setting.name == fieldType);
    const template = [] as any;

    await fieldTypeTemplate?.fields.map((field) => {
      const dataSelectedField = store.getCurrentSectionData.find(field => field.field == fieldName);
      const defaultValue = dataSelectedField.settings[field.name]

      const element = {
        type: field.type,
        name: field.name,
        label: field.label,
        defaultValue: defaultValue,
        options: field.options,
        hidden: false
      }
      template.push(element)
    })

    Promise.all(
      template.map(async (field) => {
        const conditionals = fieldTypeTemplate?.conditionals;
        field.hidden = await conditionalStatus(conditionals, field, template);
      })
    );

    return template;
  }

  const conditionalStatus = (conditionals, fieldData, template) => {
    let conditionalState = true;

    for (const conditional of conditionals) {
      const { targetField, watchField, value, result } = conditional;
      const watchFieldData = template.find(field => field.name == watchField);

      if (targetField == fieldData.name) {
        if (value == watchFieldData.defaultValue) {
          conditionalState = false;
        }
      }
    }
    return conditionalState;
  }

  const updateData = async (fieldName, fieldValue, fieldType) => {
    modalData.value = await createData(fieldType, fieldName);
  }

  return { 
    renderModal,
    modalId,
    modalTitle,
    modalRenderAmount,
    modalData,
    updateData
  }
}