import { defineStore } from "pinia";
import axios from "axios"

interface RootState {
  components: []
  status: boolean
  currentComponent: {
    uid: string
    data: any
    options: Record<string, unknown>
    status: boolean
  },
  currentSection: {
    name: string
    data: any
    status: boolean
    loading: boolean
  },
  transferData: Record<string, unknown>
}

export const useBuilder = defineStore({
  id: "mainBuilder",
  state: () =>
    ({
      components: [] as any,
      status: false,
      currentComponent: {
        uid: null,
        data: null,
        options: null,
        status: false,
      } as any,
      currentSection: {
        name: '',
        data: '',
        status: false,
        loading: false,
      } as any,
      transferData: {}
    } as RootState),
  getters: {
    getComponents(state) {
      return state.components;
    },
    getStatus(state) {
      return state.status;
    },
    getCurrentComponentUid(state) {
      return state.currentComponent.uid;
    },
    getCurrentComponent(state) {
      return state.currentComponent.data;
    },
    getCurrentComponentOptions(state) {
      return state.currentComponent.options;
    },
    getStatusComponent(state) {
      return state.currentComponent.status;
    },
    getCurrentSectionData(state) {
      return state.currentSection.data;
    },
    getCurrentSectionName(state) {
      return state.currentSection.name;
    },
    getStatusSection(state) {
      return state.currentSection.status;
    },
    getLoadingStatusSection(state) {
      return state.currentSection.loading;
    }
  },
  actions: {
    async fetchComponents() {
      try {
        const data = await axios.get('https://keetels-api.eliteswitch.com/api/components')
        this.components = data.data.result.items;
        this.status = true;
      }
      catch (error) {
        console.log(error)
      }
      return;
    },
    async fetchComponent(uid) {
      try {
        const data = await axios.get('https://keetels-api.eliteswitch.com/api/component/' + uid)
        this.currentComponent.data = data.data.result.item;
        this.currentComponent.status = true;
      }
      catch (error) {
        console.log(error)
      }
      return;
    },
    async fetchComponentOptions() {
      try {
        const data = await axios.options('https://keetels-api.eliteswitch.com/api/' + this.currentComponent.data.endpoint)
        const template = [] as any

        for (const [key, value] of Object.entries(data.data.result.data.fields)) {
          template.push( { value: key, label: key } );
        }

        this.currentComponent.options = template.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

      }
      catch (error) {
        console.log(error)
      }
      return;
    },
    async saveComponent() {
      this.currentSection.loading = true;
      try {
        await axios.put('https://keetels-api.eliteswitch.com/api/component/' + this.currentComponent.uid, this.transferData).then(() => {
          this.transferData = {};
          this.currentSection.loading = false
        });
      }
      catch (error) {
        console.log(error)
      }
    }
  },
});