const menuList = [
  { 
    icon: 'general', 
    label: 'General', 
    route: 'general'
  },
  { 
    icon: 'columns', 
    label: 'Columns', 
    route: 'columns'
  },
  { 
    icon: 'fields', 
    label: 'Fields', 
    route: 'fields'
  },
  { 
    icon: 'actions', 
    label: 'Actions', 
    route: 'actions'
  },
  { 
    icon: 'filters', 
    label: 'Filters', 
    route: 'filters'
  }
]

export default menuList;